var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-layout',[_c('w-flex',[_c('v-textarea',{attrs:{"auto-focus":"","color":"primary","counter":_vm.rules.keywordsLength,"label":_vm.keywordsFieldName,"placeholder":_vm.keywordsPlaceHolder,"rules":[
				value => {
					let result = true
					if (!value || value.trim().length <= 0) {
						result = _vm.$t('mobileapp.rules.field.required')
					}
					return result
				},
				value => {
					let result = true
					if (value && value.trim().length > _vm.rules.keywordsLength) {
						result = _vm.$tc('mobileapp.rules.field.maxlens', 0, { fieldname: _vm.keywordsFieldName.toLowerCase(), max: _vm.rules.keywordsLength })
					}
					return result
				}
			]},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }