<template>
	<w-layout>
		<w-flex>
			<v-textarea
				v-model="keywords"
				auto-focus
				color="primary"
				:counter="rules.keywordsLength"
				:label="keywordsFieldName"
				:placeholder="keywordsPlaceHolder"
				:rules="[
					value => {
						let result = true
						if (!value || value.trim().length <= 0) {
							result = $t('mobileapp.rules.field.required')
						}
						return result
					},
					value => {
						let result = true
						if (value && value.trim().length > rules.keywordsLength) {
							result = $tc('mobileapp.rules.field.maxlens', 0, { fieldname: keywordsFieldName.toLowerCase(), max: rules.keywordsLength })
						}
						return result
					}
				]"
			></v-textarea>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppKeywords',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			modified: false,
			rules: {
				keywordsLength: 100
			}
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		keywords: {
			get: function () {
				let result = this.application.keywords
				return result.split(';').join('\n')
			},
			set: function (val) {
				this.modified = true
				this.application.keywords = val.split('\n').join(';')
			}
		},
		keywordsPlaceHolder: function () {
			return this.$t('mobileapp.fields.keywords.placeholder')
		},
		keywordsFieldName: function () {
			return this.$t('mobileapp.fields.keywords.name')
		}
	},
	destroyed: function () {
		if (!this.modified) {
			this.application.keywords = this.application.keywords.replace(/%AppName%/g, this.application.title)
		}
	}
}
</script>
